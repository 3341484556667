<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos del operador" ref="pageForm" :items="items">
    </PageHeader>
    <Show ref="showDetails" :oldValue="oldValue" :newValue="newValue"></Show>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date"></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" @change="change" type="date"></vs-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <!-- <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Moneda:
                  <select
                    v-model="filterData.currencyId"
                    name="currency"
                    class="form-select form-select-sm m-2"
                    placeholder="Seleccione.."
                    @change="filtrar"
                  >
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option
                      v-for="({_id, name}) in currencies"
                      :key="_id"
                      :value="_id"
                    >
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div> -->
              <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Action:
                  <select v-model="filterData.action" name="currency"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione.." @change="change">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(action, idx) in actionsOptions" :key="idx" :value="action">
                      {{ action }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <!-- <div>
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="true"
                  class="me-4"
                  ><span class="ms-2 p-0"
                    >Descargar registros actuales</span
                  ></b-form-radio
                >
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="false"
                  ><span class="ms-2 p-0"
                    >Descargar todos los registros</span
                  ></b-form-radio
                >
              </div> -->
              <vs-button>
                <downloadexcel :fetch="exportData" :fields="json_fields" :name="fileName">
                  {{ $t('filter.downloadExcel') }}
                </downloadexcel>
              </vs-button>
              <vs-button type="button" @click="filtrar()"> {{ $t('filter.filter') }} </vs-button>
              <vs-button type="button" success @click="cancelFilter()" :disabled="!Object.entries(filterData).length">
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                      }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary class="shadow-lg">
                    </vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <TotalRegister :totalRegister="totalRegister"></TotalRegister>
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table :items="tableData" :fields="fields" responsive="sm" :filter="filter"
                  :filter-included-fields="filterOn">
                  <template #cell(createdAt)="data">
                    <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                      {{
                        moment
                          (data.item.createdAt)
                          .format("DD/MM/YYYY | HH:mm:ss")
                      }}
                      <span class="badge badge-time bg-primary rounded-pill">Server time:
                        {{
                          moment.utc(data.item.createdAt).format(
                            "DD/MM/YYYY | HH:mm:ss"
                          )
                        }}</span>
                    </div>
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <vs-button :disabled="checkDetails(data.item)" @click="openDetails(data.item)">{{ $t('helpers.look')
                      }}</vs-button>
                    </div>
                  </template>
                </b-table>
                <div :class="`${totalRegister ? 'd-none' : 'd-flex'
                  } justify-content-center`">
                  <b-spinner label="Spinning" :class="`${Object.entries(filterData).length ? 'd-none' : 'd-block'
                    }`"></b-spinner>
                </div>
                <div v-if="!totalRegister" class="text-center">
                  {{ $t('tableReports.noResults') }}
                </div>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <button class="btn btn-secondary mx-2 uil-angle-left" @click="beforePage" :disabled="!skip"></button>
                    <button class="btn btn-primary mx-2 uil-angle-right" @click="nextPage"
                      :disabled="tableData.length > limit"></button>
                  </ul>
                  <!-- <pagination
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";
import Show from "./show.vue";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import TotalRegister from "../../../../components/totalRegister.vue";
// import Pagination from "../../../../components/pagination.vue";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Auditory Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, downloadexcel, TotalRegister, Show },
  data() {
    return {
      skip: 0,
      limit: 10,
      oldValue: [],
      newValue: [],
      fileName: "Auditory.xls",
      json_fields: {
        "Type Action": "typeAction",
        Action: "action",
        "IP Address": "ipAddress",
        Route: "route",
        Resource: "resource",
        User: "userName",
        "Created At": "createdAt",
      },
      tableData: [],
      downloadSelected: "true",

      title: this.$t('sidebar.reports.subItems.stockAudit'),
      items: breadCrumbData.fluctuation,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      totalRegister: 0,
      totalPages: "...",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      actionsOptions: ["GET", "POST", "PUT", "DELETE", "PATCH"],
      filter: null,
      filterOn: [],
      currencies: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        {
          key: "typeAction",
          sortable: true,
          label: "Type-Action".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "action",
          sortable: true,
          label: "Description".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "ipAddress",
          sortable: true,
          label: "IP".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "route",
          sortable: true,
          label: "Route".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "resource",
          sortable: true,
          label: "Resource".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "user[0].userName",
          sortable: true,
          label: this.$t('betDetail.user').toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "details",
          label: this.$t('tableReports.details').toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "createdAt",
          sortable: true,
          label: this.$t('table.data').toUpperCase(),
          class: "space-nowrap",
        },
      ],

      typeform: {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      },
      clientFilter: null,
      disabledNextPage: null,
      disabledPreviousPage: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
  },
  methods: {
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    filtrar() {
      this.loading = true;
      this.$http
        .get(`/actions`, {
          params: {
            page: this.currentPage,
            // pagination: "true", //INFO: Pagination en true para poder paginar, este valor cambiará para el download excel
            limit: this.limit,
            ...this.filterData,
          },
        })
        .then(({ data }) => {
          if (data.ok) {
            this.tableData = data.actions;
            // this.disabledNextPage = data.actions.hasNextPage;
            // this.disabledPreviousPage = data.actions.hasPrevPage;
            this.totalRegister = data.actions.length;
            this.totalPages = data.actions.length;
          } else {
            this.tableData = [];
            this.totalRegister = 0;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelFilter() {
      this.filterData = {};
      this.getData();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.loading = true;
      this.$http
        .get("/actions", {
          params: {
            page: this.currentPage,
            limit: this.limit,
            // pagination: "true", //INFO: Pagination en true para poder paginar, este valor cambiará para el download excel
          },
        })
        .then(({ data }) => {

          if (data.ok) {
            this.tableData = data.actions;
            this.totalRegister = data.actions.length;
            this.totalPages = data.actions.length;
          } else {
            this.tableData = [];
            this.totalRegister = 0;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.getData();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.getData();
    },
    async exportData() {
      const { data } = await this.$http.get(`/actions`, {
        params: {
          ...this.filterData,
          limit: this.limit,
          page: this.currentPage,
          // pagination: this.downloadSelected,
        },
      });
      if (data.ok) {
        const formattedData = [];

        if (data.actions.length) {
          data.actions.forEach((element) => {
            formattedData.push({
              ...element,
              userName: element.user[0].userName,
            });
          });
        }

        return formattedData;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      };
      this.getData();
    },
    openDetails(item) {
      const oldValue = Object.entries(item.oldValue);
      const newValue = Object.entries(item.newValue);

      this.oldValue = oldValue;
      this.newValue = newValue;
      this.$refs.showDetails.modal = true;
    },
    checkDetails(item) {
      if (
        Object.entries(item.oldValue).length > 1 &&
        Object.entries(item.newValue).length > 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    change() {
      this.filtrar();
    },
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  right: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
